import axios from 'axios'



export const globalMixins = {
	data: () => ({
		globalLoading: false,
		globalError: null
	}),
	methods: {
		axios: url => new Promise((resolve,reject) => {
			axios
				.get(url)
				.then(res => {
					resolve(res.data)
				})
				.catch(error => {
					console.log(error)
					// this.globalLoading = error
					reject(error)
				})
				// .finally(() => this.globalLoading = false)
		}),
		getComponentName(type) {
			let name
			switch (type) {
				case 'heading':
					name = 'WorkHeading'
					break
				case 'paragraph':
					name = 'WorkParagraph'
					break
				case 'image':
					name = 'WorkImage'
					break
				default:
					name = null
					break;
			}
			return name
		}
	}
};