
<template>
	<div id="hoborg"
	class="ab wh b-l"
	:style="style"
	:class="[{
		siteCovered   : getSiteCovered,
		light         : getLight,
		vertical      : !getHorizontalScrolling ,
		horizontal    : getHorizontalScrolling,
		scrolled      : pageIsScrolled
	}, pageName]">

		<main-nav/>
		<side-nav/>
		<scroll-bar/>
		<main-menu/>
		<pop-up/>
		<cursor-follow/>

		<main id="main" ref="main" :class="{ pe: getPageIsGrabbing }">
			<router-view/>
			<!-- <hi-p5/> -->
		</main>
	</div>
</template>

<script>



import { mapActions, mapGetters } from 'vuex'

import '@/assets/css/fl.css';
import '@/assets/css/stylesheet.css?ver=3';
import '@/assets/css/stylesheet_media.css?ver=3';

import MainNav 			from '@/components/elements/MainNav.vue'
import SideNav 			from '@/components/elements/SideNav.vue'
import ScrollBar 		from '@/components/elements/ScrollBar.vue'
import MainMenu 		from '@/components/elements/MainMenu.vue'
import PopUp     		from '@/components/elements/PopUp.vue'
import CursorFollow     from '@/components/elements/CursorFollow.vue'
// import HiP5  			from "@/components/elements/P5.vue"

export default {
	name: "Hoborg",
	components: {
		MainNav,
		MainMenu,
		ScrollBar,
		SideNav,
		PopUp,
		CursorFollow,
		// HiP5
	},
	data: () => ({
		pageName: null
	}),
	computed: {
		...mapGetters("GeneralStore", [
			'getLight',
			'getWrapper',
			'getHorizontalScrolling',
			'getTop',
			'getMenuPresent',
			'getPageIsGrabbing',
			'getWindow',
			'getQuery',
			'getSiteCovered'
			]),
		...mapGetters("PagesStore", ['getPageInfo']),
		pageIsScrolled() {
			if(this.getMenuPresent) return false
			return this.getTop < -25
		},
		style() {
			return `--vh: ${this.getWindow.h}px`
		}
	},
	mounted() {
		this.updateWindowValues()
		this.updateWrapperValue()
		this.updateTouch('ontouchstart' in document.documentElement)
		window.addEventListener("resize", this.resizeReset, false)
		window.addEventListener('wheel', this.isScroll)
		this.pageName = this.$route.name
		this.updateCurrentPage(this.pageName)
		this.observer = new MutationObserver(mutations => { mutations.forEach(() => this.updateWrapperValue()) })
	},
	methods: {
		...mapActions("GeneralStore", [
				'updateTouch',
				'updateCursor',
				'updateCurrentPage',
				'updateWindow',
				'updateWrapperHeight',
				'updateWrapperWidth',
				'updateTop',
				'updateLeft',
				'updatePopupActive',
			]),
		resizeReset() {
			this.updateWindowValues()
			this.updateWrapperValue()
		},
		updateWindowValues() {
			this.updateWindow({ w : window.innerWidth, h : window.innerHeight })
			this.updateWrapperValue()
		},
		updateWrapperValue() {
			if(this.getWrapper) {
				this.updateWrapperWidth( this.getWrapper.getBoundingClientRect().width)
				this.updateWrapperHeight( this.getWrapper.getBoundingClientRect().height)
				this.updateLeft( this.getWrapper.getBoundingClientRect().left)
				this.updateTop( this.getWrapper.getBoundingClientRect().top)
			}
		},
		isTouch() {
			window.addEventListener('wheel', this.isScroll)
			window.removeEventListener("touchstart", this.isTouch)
			this.updateTouch(true)
		},
		isScroll() {
			window.removeEventListener('wheel', this.isScroll)
			window.addEventListener("touchstart", this.isTouch)
			this.updateTouch(false)
		},
		changePage(route) {
			if(route.name == this.pageName) return
			this.pageName = route.name
			this.updateCursor(false)
			this.updateCurrentPage(route.name)
			this.updatePopupActive(false)
			this.updateWindowValues()
			this.updateTouch('ontouchstart' in document.documentElement)
			window.addEventListener('wheel', this.isScroll)
		}
	},
	watch: {
		$route(route) { this.changePage(route) },
		getWrapper(wrapper) {
			this.updateWrapperValue()
			this.observer.disconnect()
			this.observer.observe(wrapper, { attributes : true, attributeFilter : ['style'] })
		},
		getQuery(query) {
			query = query && query.name ? query.name : query
			if(query == this.$route.query.s) return

			let name         = this.$route.name
			let finalQuery   = { s: query }

			query = query ? { name, query: finalQuery } : { name }
			this.$router.push(query)
		}

	}
}
</script>

<style>
	@import url('assets/fonts/fonts.css');
</style>