



const state = {
    cover: {},
    poster: {},
    banner: {}
}

// GETTERS
const getters = {
    getImage: state => type =>state[type]
}

// ACTION
const actions = {
    async addImage({ commit }, res) { commit('addImage', res) }
}

// MUTATION
const mutations = {
    addImage: (state, res) => {
        let type = res.type
        let image = res.image
        state[type] = {
            ...state[type],
            ...image
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}