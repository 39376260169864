<template>
    <section class="fl-c">
        <div class="section_content fl-c">
            <!-- <div class="section_n fl-c"><span>1</span><span class="_n_l">/</span><span>3</span></div> -->
            <div class="main-page_left">
                <div class="mono fhp_h">
                    <h1>
                        <span>Ali Pordeli</span>
                        <span class="header_span">{{ getTitle }}</span>
                    </h1>
                </div>
                <div class="stt">{{ getSlogan }}</div>
            </div>
            <div class="main-page_image fl-c">
                <img :src="isDark ? '/assets/img/mainpage/mainpage-top-dark.jpg' : '/assets/img/mainpage/mainpage-top.jpg'" alt="Ali Pordeli">
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'HiSection',
    data: () => ({

    }),
    computed: {
        ...mapGetters("GeneralStore", ['getTouch']),
        ...mapGetters("PagesStore", ['getTitle', 'getSlogan', 'getPageInfo']),
        isDark() {
            return !this.getPageInfo('home').light
        }

    }
}
</script>