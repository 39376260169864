<template>
	<div id="site">
		<div id="site_wrapper">
			<hoborg v-if="allLoaded" />
		</div>
		<page-cover v-if="allLoaded"/>
		<div id="loading" v-if="loading">
			<div class="loading_wrapper">
				<svg viewBox="0 0 36.3 40.1" v-bind:class="'ap_logo'" v-bind:ref="'logo'" v-bind:qsvg="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M29.1 20.2h-8.4L36.3 4.6h-.8L19.9 20.2c-1.3 0-2-.5-2.3-1.3-.3-.8-7-17.8-7-17.8L10.2 0 2.7 18.6s-.6 1.6-1.9 1.7H0v.6h6.3v-.6H5.2c-2.1 0-1.3-2.2-1.3-2.3L5 15.2h7.8l1.7 4.3c.1.2.2.9-.5.9h-1.4v.6h6.7L3.6 36.5h.8l15.7-15.7h.4c1.3 0 1.6.9 1.6 1.8v15.9c0 .4-.2 1.1-1 1.1H20v.6h7.4v-.6h-1.1c-.8 0-.9-.7-1-1.1v-7h3.8c3.1 0 5.6-2.5 5.6-5.6s-2.5-5.7-5.6-5.7zM5.3 14.4L9 5.3l3.6 9.1H5.3zm21.5 16.5h-1.4V20.8h1.4c2.8 0 5 2.3 5 5s-2.3 5.1-5 5.1z"/></svg>
				<div class="ap_logo_w">
					<svg viewBox="0 0 36.3 40.1" v-bind:class="'ap_logo'" v-bind:qsvg="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M29.1 20.2h-8.4L36.3 4.6h-.8L19.9 20.2c-1.3 0-2-.5-2.3-1.3-.3-.8-7-17.8-7-17.8L10.2 0 2.7 18.6s-.6 1.6-1.9 1.7H0v.6h6.3v-.6H5.2c-2.1 0-1.3-2.2-1.3-2.3L5 15.2h7.8l1.7 4.3c.1.2.2.9-.5.9h-1.4v.6h6.7L3.6 36.5h.8l15.7-15.7h.4c1.3 0 1.6.9 1.6 1.8v15.9c0 .4-.2 1.1-1 1.1H20v.6h7.4v-.6h-1.1c-.8 0-.9-.7-1-1.1v-7h3.8c3.1 0 5.6-2.5 5.6-5.6s-2.5-5.7-5.6-5.7zM5.3 14.4L9 5.3l3.6 9.1H5.3zm21.5 16.5h-1.4V20.8h1.4c2.8 0 5 2.3 5 5s-2.3 5.1-5 5.1z"/></svg>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Hoborg from '@/Hoborg.vue'
import PageCover     	from '@/components/elements/PageCover.vue'
import { mapActions, mapGetters } from 'vuex'
import gsap 			from "gsap"
import '@/assets/css/initial.css'



export default {
	name: 'Site',
	data: () => ({
		allLoaded             : false,
		imageLoadedPercent    : 0,
		loading               : true,
		// loadingCoverTime      : 0.1,
		// loadingLoopDelay      : 0.1
		loadingCoverTime      : 1.5,
		loadingLoopDelay      : 0.5
	}),
    components: {
		Hoborg,
		PageCover
	},
	mounted() {
		this.startLoading()
		this.loadWorks()
		this.loadPages()
	},
	computed: {
		...mapGetters("WorksStore", ['worksLoaded','getWorksId']),
		...mapGetters("PagesStore", ['pagesLoaded']),
		statesLoaded() { return this.worksLoaded && this.pagesLoaded },
	},
	methods: {
		...mapActions("WorksStore", ['loadWorks']),
		...mapActions("PagesStore", ['loadPages']),
		imageLoader(files) {
			files.map(e => {
				let img = new Image()
				img.onload = () => {
					this.imageLoadedPercent+= 20/files.length
				}
				img.src = e
			})
		},
		startLoading() {
			// Loading Steps
			let progress = 0
			if(this.worksLoaded) progress +=20
			if(this.pagesLoaded) progress +=20
			progress += Math.ceil(this.imageLoadedPercent)

			let height = this.$refs.logo.getBoundingClientRect().height
				height = height * (progress / 100)

			gsap.to('.ap_logo_w', this.loadingLoopDelay, { height, ease: "power1.inOut", onComplete: () => {
				if (progress == 100) {
					this.endLoading()
				} else {
					this.startLoading()
				}
			} })
		},

		endLoading() {
			this.allLoaded = true
			let lo = '#loading'
			let wr = '.loading_wrapper'
			let t = this.loadingCoverTime
			let ease = "power3.inOut"

			gsap.to(wr, t, { opacity:0, ease, scale:1.2 })

			gsap.to(lo, t, {delay:0.1, ease, height: 0, onComplete: () => {
				this.loading = false
			} })
		},
		preloadImages() {

			let ids = this.getWorksId

			let cover    = []
			let poster   = []
			let banner   = []

			ids.map(id => {
				cover.push('/assets/img/cover/' + id + '.jpg')
				poster.push('/assets/img/poster/' + id + '.jpg')
				banner.push('/assets/img/banner/' + id + '.jpg')
			})

			this.imageLoader(cover)
			this.imageLoader(poster)
			this.imageLoader(banner)
		}
	},
	watch: {
		statesLoaded(val) {
			if(val) this.preloadImages()
		}
	}
}
</script>