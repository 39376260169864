let c = console.log
c()

const state = {
    popup: {
        component   : null,
        payload     : null
    },
    popupActive     : false,
    popupMoving    : false,
    window : {
        w : window.innerWidth,
        h : window.innerHeight
    },
    menuStatus            : false,
    currentPage           : null,
    topMenuShow           : true,
    breakpoints           : [1200, 800, 300],
    sections              : [],
    sectionsNames         : [],
    menuPresent           : false,
    touch                 : true,
    cursor                : false,
    routeChanging         : false,
    grabCursor            : false,
    cursorDark            : false,
    siteCovered           : false,
    pageMoving            : false,
    pageIsGrabbing        : false,
    canGrab               : false,
    scrollbarColor        : null,
    sideBar               : 'top',
    cursorEl              : {over:null, out:null, x:null, y:null},
    light                 : false,
    snap                  : true,
    isMobile              : false,
    query                 : null,
    horizontalScrolling   : true,
    triggerPosition       : 'center',
    wrapperWidth          : 0,    // Width of the wrapper
    wrapperHeight         : 0,    // Height of the wrapper
    wrapper               : null, // Width of the wrapper
    left                  : 0,    // Actual number of the distance of the wrapper to the left
    top                   : 0,    // Actual number of the distance of the wrapper to the top
    dis                   : 0,    // Number of dis
    limit                 : 0,
    handle: {
        x         : 0,
        y         : 0,
        grabbed   : false
    }
}

// GETTERS
const getters = {
    state                     : state => state,
    getPopup                  : state => state.popup,
    getPopupActive            : state => state.popupActive,
    getPopupMoving            : state => state.popupMoving,
    getMenuStatus             : state => state.menuStatus,
    getSections               : state => state.sections,
    getSectionsNames          : state => state.sectionsNames,
    getMenuPresent            : state => state.menuPresent,
    getTouch                  : state => state.touch,
    getTriggerPosition        : state => state.triggerPosition,
    getRouteChanging          : state => state.routeChanging,
    getTopMenuShow            : state => state.topMenuShow,
    getCursor                 : state => state.cursor,
    getSiteCovered            : state => state.siteCovered,
    getGrabCursor             : state => state.grabCursor,
    getSnap                   : state => state.snap,
    getLight                  : state => state.light,
    getCursorDark             : state => state.cursorDark,
    getCursorEl               : state => state.cursorEl,
    getWindow                 : state => state.window,
    getWrapper                : state => state.wrapper,
    getWrapperWidth           : state => state.wrapperWidth,
    getWrapperHeight          : state => state.wrapperHeight,
    getLimit                  : state => state.limit,
    getHandle                 : state => state.handle,
    getLeft                   : state => state.left,
    getTop                    : state => state.top,
    getScrollbarColor         : state => state.scrollbarColor,
    getQuery                  : state => state.query,
    getBreakpoints            : state => state.breakpoints,
    getPageMoving             : state => state.pageMoving,
    getHorizontalScrolling    : state => state.horizontalScrolling,
    getCanGrab                : state => state.canGrab,
    getPageIsGrabbing         : state => state.pageIsGrabbing,
    getSideBar                : state => state.sideBar,
    getMobile                 : state => state.isMobile
}


// ACTION
const actions = {
    toggleMenu({ commit }, res) { commit('changeMenuStatus', res)},
    menuPresent({ commit }, res) { commit('menuPresent', res)},
    updateTouch({commit}, res) { commit('updateTouch', res)},
    updateCursor({commit}, res) { commit('updateCursor', res)},
    updateRouteChanging({commit}, res) { commit('updateRouteChanging', res)},
    updateGrabCursor({commit}, res) { commit('updateGrabCursor', res)},
    updateSnap({commit}, res) { commit('updateSnap', res)},
    updateLight({commit}, res) { commit('updateLight', res)},
    updateCursorDark({commit}, res) { commit('updateCursorDark', res)},
    updateLimit({commit}, res) { commit('updateLimit', res)},
    updateTopMenuShow({commit}, res) { commit('updateTopMenuShow', res)},
    updateHandle({commit}, res) { commit('updateHandle', res)},
    updateLeft({commit}, res) { commit('updateLeft', res)},
    updateSiteCovered({commit}, res) { commit('updateSiteCovered', res)},
    updateTop({commit}, res) { commit('updateTop', res)},
    updateBreakpoints({commit}, res) { commit('updateBreakpoints', res)},
    updatePageMoving({commit}, res) { commit('updatePageMoving', res)},
    updateCanGrab({commit}, res) { commit('updateCanGrab', res)},
    updateQuery({commit}, res) { commit('updateQuery', res)},
    updateTriggerPosition({commit}, res) { commit('updateTriggerPosition', res)},
    updatePageIsGrabbing({commit}, res) { commit('updatePageIsGrabbing', res)},
    updateScrollbarColor({commit}, res) { commit('updateScrollbarColor', res)},
    updatePopup({commit}, res) { commit('updatePopup', res)},
    updatePopupActive({commit}, res) {commit('updatePopupActive', res) },
    updatePopupMoving({commit}, res) {commit('updatePopupMoving', res) },
    updateCurrentPage({commit}, res) {commit('updateCurrentPage', res)},
    updatePage({ commit, state }) {
        let wrapper = state.popupActive ? 'popup_wrapper' : 'page'
            wrapper = document.getElementById(wrapper)
        commit('updateWrapper', wrapper)
        commit('calculateWrapperDimentions')
        commit('calculateLimit')
    },
    updateWindow({commit}, res) {
        commit('updateWindow', res)
        commit('calculateLimit')
    },
    updateHorizontalScrolling({commit}, res) {
        commit('updateHorizontalScrolling', res)
        commit('calculateLimit')
    },
    updateWrapperWidth({commit}, res) {
        commit('updateWrapperWidth', res)
        commit('calculateLimit')
    },
    updateWrapperHeight({commit}, res) {
        commit('updateWrapperHeight', res)
        commit('calculateLimit')
    },
    updateCursorEl({commit, state}, res) {
        let options = {
            x: true,
            y: true
        }
        if(res[1]) Object.assign(options, res[1])

        res         = res[0] ? res[0] : res
        let hover   = res.type == "mousemove" ? true : false
        let el      = {over:null, out:null}
        if(state.touch) {
            commit('updateCursorEl', el)
            return
        }

        if(hover) {
            el.over = res.target
            el.x = options.x ? res.pageX : null
            el.y = options.y ? res.pageY : null
            commit('updateCursor', true)
        } else {
            el.out = res.target
            commit('updateCursor', false)
        }
        commit('updateCursorEl', el)
    },
    setSections({ commit, rootState, dispatch }, sections) {
        dispatch('updatePage')

        commit('setSections', Object.values(sections[0]))
        commit('updateSectionsNames', Object.keys(sections[0]))

        let info = rootState['PagesStore'][sections[1]]
        commit('updateHorizontalScrolling', info.horizontal)
        commit('updateSnap', info.snap)
        commit('updateLight', info.light)
        commit('updateCanGrab', info.grab)
        commit('updateSidebar', info.sideBar)
        commit('updateTriggerPosition', info.triggerPosition)
    },
    swapLight({ commit, state, rootState }) {
        commit('PagesStore/swapLight', state.currentPage, { root: true })
        commit('updateLight', rootState['PagesStore'][state.currentPage].light)
    }
}

// MUTATION
const mutations = {
    updateCurrentPage: (state, res) => {
        state.currentPage = res
    },
    changeMenuStatus: (state, res) => {
        state.menuStatus = res ? res : !state.menuStatus
    },
    menuPresent: (state, res) => {
        state.menuPresent = res
        if(res) {
            state.cursorDark = false
        } else {
            state.cursorDark = state.light ? true : false
        }
    },
    updateLight: (state, res) => {
        state.light = res
        state.cursorDark = res
    },
    calculateLimit: (state) => {
        if(!state.wrapper) return

        state.limit = state.horizontalScrolling ?
        state.wrapperWidth - state.window.w :
        state.wrapperHeight - state.window.h
        state.isMobile = state.window.w < 600
    },

    calculateWrapperDimentions: (state) => {
        if(!state.wrapper) return
        let rect = state.wrapper.getBoundingClientRect()
        state.wrapperWidth    = rect.width
        state.wrapperHeight   = rect.height
        state.top             = rect.top
        state.left            = rect.left
    },

    updateTop: (state, res) => {
        if(state.routeChanging) return
        state.top = res
    },
    updateTouch                 : (state, res) => {
        state.touch = res
        if(res) state.snap = false
    },
    updateWrapper               : (state, res) => { state.wrapper = res },
    updateSnap                  : (state, res) => { state.snap = res},
    updateWrapperWidth          : (state, res) => { state.wrapperWidth = res },
    updateWrapperHeight         : (state, res) => { state.wrapperHeight = res },
    updateHorizontalScrolling   : (state, res) => { state.horizontalScrolling = res},
    updateTriggerPosition       : (state, res) => { state.triggerPosition = res},
    updateWindow                : (state, res) => { state.window = res },
    updateCursorDark            : (state, res) => { state.cursorDark = res },
    updateCursorEl              : (state, res) => { state.cursorEl = res },
    updateHandle                : (state, res) => { state.handle = res },
    updateLeft                  : (state, res) => { state.left = res },
    updateTopMenuShow              : (state, res) => { state.topMenuShow = res },
    updateBreakpoints           : (state, res) => { state.breakpoints = res },
    updateQuery                 : (state, res) => { state.query = res },
    updatePageMoving            : (state, res) => { state.pageMoving = res },
    updateSiteCovered           : (state, res) => { state.siteCovered = res },
    updateCanGrab               : (state, res) => { state.canGrab = res },
    updateSidebar               : (state, res) => { state.sideBar = res },
    updateRouteChanging         : (state, res) => { state.routeChanging = res },
    updateScrollbarColor        : (state, res) => { state.scrollbarColor = res },
    updatePageIsGrabbing        : (state, res) => { state.pageIsGrabbing = res },
    setSections                 : (state, sections) => { state.sections = Object.keys(sections).length ? sections : null },
    updateSectionsNames         : (state, names) => { state.sectionsNames = Object.keys(names).length ? names : null },
    updatePopup                 : (state, res) => { state.popup = res },
    updatePopupActive           : (state, res) => { state.popupActive = res },
    updatePopupMoving           : (state, res) => { state.popupMoving = res },
    updateGrabCursor           : (state, res) => {
        state.cursor = false
        state.grabCursor = res
    },
    updateCursor                : (state, res) => {
        if(state.grabCursor) return
        state.cursor = res
    },
}

export default { namespaced: true,state,getters,actions,mutations }