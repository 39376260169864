<template>
    <div id="popup" class="fi wh t-l">
        <div id="popup_wrapper" class="fl-c-t">
            <div class="p_content fl-co-st">
                <component
                    v-if="thisComponent && !isClosed"
                    v-bind="{ payload }"
                    :is="thisComponent" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import gsap from "gsap"
import p5 from 'p5' // eslint-disable-line no-unused-vars





export default {
    name: "PopUp",
    data: () => ({
        isClosed: true
    }),
    computed: {
        ...mapGetters("GeneralStore", ['getPopup','getPopupActive','getPopupMoving']),
        popUp() { return this.getPopup },
        active() { return this.getPopupActive},
        component() { return this.popUp.component },
        payload() { return this.popUp.payload },
        thisComponent() {
            if(!this.component) return null
            return () => import(`@/components/popup/${this.component}.vue`)
        }
    },
    destroyed() {
        this.updatePopupActive(false)
    },
    methods: {
        ...mapActions("GeneralStore", ['updatePopupActive', 'updatePopupMoving','updateCursorEl']),
        togglePopup(val) {

            let p       = '#popup'
            let m       = '#section_wrapper'
            let w       = '#popup_wrapper'
            let t       = 0.7
            let ease    = 'power1.out'

            let opacity = val ? 1 : 0
            let height  = val ? '100%' : 0
            let y       = val ? 50 : 0
            let yw      = val ? 0 : -50

            this.updatePopupMoving(true)

            gsap.killTweensOf(p,m,w)
            gsap.to(p, t, { height, ease })
            gsap.to(m, t, { y, ease })
            gsap.to(w, t, { y:yw, opacity, ease, onComplete: () => { this.updatePopupMoving(false) } })
        }
    },
    watch: {
        getPopupActive(val) {
            if(val) this.isClosed = false
            this.togglePopup(val)
        },
        getPopupMoving(val) {
            if(!val && !this.getPopupActive) {
                this.isClosed = true
            }
        }
    }
}
</script>