<template>
  <div id="page">
    <div id="section_wrapper" class="home_wrapper fullwidth">
      <hi-section ref="intro"></hi-section>
      <featured-works ref="featured"></featured-works>
      <me-section ref="statement"></me-section>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import HiSection from '@/components/home/HiSection.vue'
import MeSection from '@/components/home/MeSection.vue'
import FeaturedWorks from '@/components/home/FeaturedWorks.vue'


export default {
	name: 'Home',
	components: {
		HiSection,
		MeSection,
		FeaturedWorks,

	},
	mounted() {
		this.setSections([this.$refs, this.$route.name])
	},
	methods: {
		...mapActions ("GeneralStore", ['setSections'])
	},
	}
</script>