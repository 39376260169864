


import axios from 'axios'

const state = {
    loaded: false,
    works: null,
    technology: null
}

const getters = {
    worksLoaded: state => state.loaded,
    getWork: state => (slug=null) => {
        return slug ? state.works.filter(e => slug == e.slug) : state.works
    },
    getTechnology: state => tech => state.technology[tech],
    getFeaturedWork: state => state.works.filter(e => e.featured),
    getWorksId: state => state.works.reduce( (k,e) => [...k, e.id], [])
}

const actions = {
    async loadWorks({ commit }) {
        let res = await axios('/data/works.json')
        commit('loadWorks', res.data)
    }
}

const mutations = {
    loadWorks: (state, res) => {
        state.works         = res.works
        state.technology    = res.technology
        state.loaded        = true
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}