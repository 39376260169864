<template>
    <div class="menu_wrapper fi wh t-r fl-t-r">
        <nav id="menu" class="fl-c">
            <div class="menu_content">
                <div class="menu_buttons fl-co-c">
                    <div class="menu_button" v-for="(link, i) in navLinks" :key="i">
                        <router-link
                            :to="{name: link.name}"
                            :class="{ visited: getVisited(link.name)}"
                            class="fl-c-l menu_link"
                            exact>
                            <div @mouseenter="updateCursor(true)" @mouseleave="updateCursor(false)">
                                {{ link.text }}
                                <span class="ab w1" v-if="!getTouch"></span>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </nav>
        <!-- <div id="nav_skin" class="fi wh t-l"></div> -->
    </div>
</template>

<script>
import gsap from "gsap"
import { mapActions, mapGetters } from 'vuex'



export default {
    data: () => ({
        go: true,
        navLinks: [
            { name: 'home', text: 'Intro' },
            { name: 'works', text: 'Work' },
            { name: 'about', text: 'About' },
            { name: 'contact', text: 'Contact' }
        ]
    }),
    computed: {
        ...mapGetters ("GeneralStore", [
            'getMenuStatus',
            'getTouch',
            'getWindow',
            'getSiteCovered'
        ]),
    },
    methods: {
        ...mapActions ("GeneralStore", [
            'menuPresent',
            'updateCursor',
            'updatePageMoving'
        ]),

        closed() {
            this.menuPresent(false)
            this.updatePageMoving(false)
            if(this.go) this.menuPresent(false)
            else this.menuPresent(true)
        },
        toggleMenu(status, ti=null) {
            let t   = gsap.timeline({ repeat:false })
            let m   = '#menu'
            let w   = '.menu_wrapper'
            let ma   = "#section_wrapper"
            let ease = "power3.inOut"
            t.killTweensOf(w,m,ma)
            t.clear()

            if(ti == null) {
                ti = this.getWindow.w/3000 + 0.2
                if(ti<0.5) ti = 0.6
            }

            let x = - this.getWindow.w / 15 // for page move behind
            let mainX = this.getWindow.w / -10

            if(status) {
                this.menuPresent(true)
                this.go = false
                t.to(m, 0, { x:mainX }, 'start')
                t.to(w, ti, { width:'100%',ease}, 'a')
                t.to(m, ti, { x:0, ease }, 'a')
                t.to(ma, ti, { x, ease: "power1.in" }, 'a')
            } else {
                this.go = true
                t.to(w, ti, { width:0, ease}, 'a')
                t.to(m, ti, { x:mainX, ease }, 'a')
                t.to(ma, ti, { x:0 }, 'a')
                t.then(() => { this.closed() })
            }
        },
        getVisited(name) { return name == this.$route.name }
    },
    watch: {
        getMenuStatus(val) { this.toggleMenu(val) },
        getSiteCovered(v) {
            if(!v || this.getMenuStatus) return
            this.toggleMenu(false, 0)
        }
    }
}
</script>