<template>
    <div id="side_nav"
        class="ab h1 r-t fl-co-c"
        :class="[{ open: getMenuStatus, pe: getPageIsGrabbing }]">
        <router-link
            tag="div"
            @mouseenter.native="updateCursor(true)"
            @mouseleave.native="updateCursor(false)"
            :to="{name: 'home'}"
            :class="{pe: this.$route.name == 'home'}"
            class="branding upper fl-c nav_btn"

            exact>
                <!-- <div>Ali Pordeli</div> -->
                <svg viewBox="0 0 36.3 40.1" v-bind:qsvg="''" v-bind:class="'pe'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M29.1 20.2h-8.4L36.3 4.6h-.8L19.9 20.2c-1.3 0-2-.5-2.3-1.3-.3-.8-7-17.8-7-17.8L10.2 0 2.7 18.6s-.6 1.6-1.9 1.7H0v.6h6.3v-.6H5.2c-2.1 0-1.3-2.2-1.3-2.3L5 15.2h7.8l1.7 4.3c.1.2.2.9-.5.9h-1.4v.6h6.7L3.6 36.5h.8l15.7-15.7h.4c1.3 0 1.6.9 1.6 1.8v15.9c0 .4-.2 1.1-1 1.1H20v.6h7.4v-.6h-1.1c-.8 0-.9-.7-1-1.1v-7h3.8c3.1 0 5.6-2.5 5.6-5.6s-2.5-5.7-5.6-5.7zM5.3 14.4L9 5.3l3.6 9.1H5.3zm21.5 16.5h-1.4V20.8h1.4c2.8 0 5 2.3 5 5s-2.3 5.1-5 5.1z"/></svg>
        </router-link>
        <router-link
            v-show="showWork"
            tag="div"
            @mousemove.native="updateCursorEl" @mouseleave.native="updateCursorEl"
            :to="{name: 'works'}"
            class="fl-c upper work_btn nav_btn"

            exact>
            <span class="pe">{{ worksTitle }}</span>
        </router-link>
        <light-dark/>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LightDark 			from '@/components/elements/LightDark.vue'

export default {
    name: "SideNav",
    components: {
        LightDark
    },
    computed: {
        ...mapGetters("GeneralStore", [
            'getMenuStatus',
            'getPageIsGrabbing',
            'getSiteCovered'
        ]),
        ...mapGetters("PagesStore", ['getPageInfo']),
        worksTitle() {
            return this.getPageInfo('works').title
        },
        showWork() {
            let rn = this.$route.name
			let con = rn == 'work' || rn == 'works'
            return con ? false : true
        }
    },
    methods: {
        ...mapActions ("GeneralStore", ['toggleMenu','updateCursor','updateCursorEl']),
    }
}
</script>