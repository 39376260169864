<template>
    <div id="cursor">
		<div id="flmc"
			v-if="!getTouch"
			:class="{dark:getCursorDark, grabbed: getGrabCursor , hovered: getCursor && !navGrab, nav: navGrab, input: getCursor == 'input' }"
			class="size pe"
			>
			<svg id="flmc_c" viewBox="0 0 22 22" stroke-dashoffset="1000"><circle cx="11" cy="11" r="10.5"/></svg>
			<div id="flmc_f" class="ab wh">
				<div id="flmc_f_wrapper" class="ab wh t-l" >
					<svg v-if="!cornerRight" class="ab v-c flm_fl" viewBox="0 0 6.7 13.5"><polygon points="6.7,0 0,6.7 6.7,13.5 "/></svg>
					<svg v-if="!cornerLeft" class="ab v-c flm_fr" viewBox="0 0 6.7 13.5"><polygon points="0,13.5 6.7,6.7 0,0 "/></svg>
				</div>
			</div>
		</div>
		<div id="flmc_po"
			v-if="!getTouch"
			:class="{ dark:getCursorDark,  grabbed: getGrabCursor, hovered: getCursor && !navGrab, input: getCursor == 'input' }"
			class="pe size">
			<span class="size fl sizer"></span>
			<svg viewBox="0 0 23.8 23.8" v-bind:qsvg="''" v-bind:class="'ab vh-c'" v-bind:id="'flmc_input'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12.8 4.1L17.4 2 16.9.8 12.2 3 7.5.8 6.9 2l4.6 2.1v15.6l-4.6 2.1.6 1.2 4.7-2.2 4.7 2.2.5-1.2-4.6-2.1z"/></svg>
		</div>
    </div>
</template>

<script>
import gsap from "gsap"
import { mapActions, mapGetters } from 'vuex'




export default {
    name: "CursorFollow",
	data: () => ({
		cornerLeft              : true,
		cornerRight             : false
	}),
    mounted() {
        window.addEventListener("mousemove", this.circleFollow)
        window.addEventListener("mousedown", this.circleFollow)
        window.addEventListener("click", this.circleFollow)
    },
    computed: {
        ...mapGetters("GeneralStore", [
            'getCursorDark',
            'getGrabCursor',
            'getCursor',
            'getCursorEl',
            'getMobile',
            'getTouch',
            'isGrabbed',
            'getHandle',
            'getLeft',
            'getTop',
            'getMenuPresent',
            'getHorizontalScrolling'
        ]),
        navGrab() { return this.isGrabbed},
        cOver() { return { el:this.getCursorEl.over, x:this.getCursorEl.x, y:this.getCursorEl.y } },
        cOut() { return this.getCursorEl.out },
        handleX() { return this.getHandle.x },
        handleY() { return this.getHandle.y },
        isGrabbed() { return this.getHandle.grabbed },
        horizontal() { return this.getHorizontalScrolling },
        topLeft() { return this.horizontal ? this.getLeft : this.getTop },
    },
    methods: {
        ...mapActions("GeneralStore", [
            'updateCursor'
        ]),
        circleFollow(e) {
            if(!document.getElementById('flmc')) return

            let x = e.clientX
            let y = e.clientY

            gsap.killTweensOf('#flmc', '#flmc_po')
            gsap.to('#flmc_po', 0, { x: x, y: y, ease:'none'})
            if(this.isGrabbed) return
            gsap.to('#flmc', .5, { x: x, y: y, ease:'back.out(2)'})
        },
        moveCursorToHandle() {
            if(!this.isGrabbed) return
            gsap.killTweensOf('#flmc')
            gsap.to('#flmc', 0.4, { x: this.handleX, y: this.handleY, ease:'power1.out'})
        },
    },
    watch: {
        cOver(el) {
            if(this.getMobile) return

            let x  = el.x
            let y  = el.y
            el     = el.el
            if(!el) return

            if(x) {
                let elw = el.offsetWidth / 2 // Half of the width of the hovered element
                let elx = el.getBoundingClientRect().left + elw // Coordinate of the center of the element
                x = x - elx // How much center should move base don mouse position
            } else {
                x = 0
            }

            if(y) {
                let elh   = el.offsetHeight / 2 // Half of the width of the hovered element
                let ely   = el.getBoundingClientRect().top + elh // Coordinate of the center of the element
                y         = y - ely // How much center should move based on mouse position
            } else {
                y = 0
            }

            let child = el.firstChild

            gsap.killTweensOf(child)
            gsap.to(child, 0.3	, { x, y })
        },
        cOut(el) {

            if(!el || this.getMobile) return

            el = el.firstChild

            gsap.killTweensOf(el)
            gsap.to(el, 1, { x: 0, y:0, scale:1, ease:'power1.in' })
        },
        handleY() {
            this.moveCursorToHandle()
        },
        isGrabbed() {
            this.moveCursorToHandle()
        },
        topLeft(dis) {
            this.cornerLeft = dis > -25 ? true : false
            this.cornerRight = dis < -this.getLimit + 25 ? true : false
        },
    },
}
</script>