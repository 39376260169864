<template>
    <div class="scroll_bar_wrapper fl-co-c ab v-c" v-show="!getTouch">
        <div id="scroll_bar"
            class="after fl-c"
            ref="scrollbar"
            @mousedown="grabStart"
            @mouseenter="updateCursor(true)"
            @mouseleave="updateCursor(isGrabbed ? true : false)"
            v-show="sections && !getTouch && showScroll"
            :class="{ grabbed: isGrabbed, open: getMenuStatus, pe: pageIsGrabbing, dark: getScrollbarColor =='dark', light: getScrollbarColor == 'light' }">
            <div class="scroll_bar-handle fl-c">
                <div class="handle_nch ab hv-c sizew"></div>
            </div>
            <div class="barGrab ab h1 fl-c sizew" ref="bar"></div>
        </div>
        <!-- <span v-if="currentRoute == 'home'" class="hold_d_m upper">Hold & Drag</span> -->
    </div>
</template>
<script>
import gsap from "gsap"
import { mapGetters, mapActions } from 'vuex'



export default {
    data: () => ({
        element                 : '#page',
        pageGrabStartTimeout    : null,
        isGrabbed               : false,
        gStart                  : null,
        moveTimeout: null,
        lastScrollTime          : 0,
        scrollingSpeed          : 1,
        swipeSpeed              : 1,
        handlePosition          : [0,0],
        bar                     : null,
        dir                     : null,
        startingDir             : null,
        canMove                 : true,
        currentRoute            : null,
        barHeight               : 0,
        currentSection          : 0,
        swipingVelocity         : [],
        tooltipHeight           : 5, // in vh (will be transfered later)
        dis                     : 0,
        tStartX                 : 0,
        tStartY                 : 0,
        tempDis                 : 0,
        lastDistance            : 0,
        moveDelay               : 100, // Millisecond
        finalMoveDelay          : 800,
        pageIsGrabbing          : false,
        touching                : false,
        pageGrabStarted         : false,
        worksGrabTimeout        : null,
        lastTouches             : [0,0],
        tl                      : gsap.timeline({ repeat:false })
    }),
    computed: {
        ...mapGetters("GeneralStore",
            [
                "getSections",
                "getMenuStatus",
                "getMenuPresent",
                "getSwipe",
                "getTouch",
                "getSnap",
                "getWindow",
                "getLimit",
                "getWrapperWidth",
                "getWrapperHeight",
                "getLeft",
                "getTop",
                "getWrapper",
                "getPageMoving",
                "getHorizontalScrolling",
                "getCanGrab",
                'getSectionsNames',
                'getScrollbarColor',
                'getPopupActive',
                'getGrabCursor',
                'getRouteChanging',
                'getQuery',
                'getCursor'
            ]
        ),
        horizontal() { return this.getHorizontalScrolling },
        pageSize() { return this.horizontal ? this.getWindow.w : this.getWindow.h },
        wrapperSize() { return this.horizontal ? this.getWrapperWidth : this.getWrapperHeight},
        distance() { return this.horizontal ? this.getLeft : this.getTop },
        sections() {
            let sections    = this.getSections
            if(!sections && this.getWrapper) gsap.to(this.element, 0, {x:0, y:0})
            return sections
        },
        pageGrabbingSpeed() { return this.pageSize * 0.5 },
        numberOfSections() {
            return this.sections ? this.sections.length : 0
        },

        limit() {
            return this.getLimit
        },
        leftover() {
            return this.limit + this.getLeft
        },
        showScroll() {
            return this.pageSize >= this.wrapperSize && this.wrapperSize != 0 ? false : true
        },
        threshold() {
            return this.getTouch || this.isGrabbed ? this.pageSize / 20 : (this.pageSize / 80) * this.scrollingSpeed
        },
        updateHandleValues() {
            let grabbed = this.isGrabbed
            if (this.pageIsGrabbing) grabbed = false

            return {
                x         : this.handlePosition[0],
                y         : this.handlePosition[1],
                grabbed   : grabbed
            }
        }
    },
    methods: {
        ...mapActions("GeneralStore", [
            'updateCursor',
            'updateHandle',
            'updatePageMoving',
            'updatePage',
            'updatePageIsGrabbing',
            'updateQuery',
            'updateGrabCursor',
        ]),

        getVisible() {
            let sections =  Array.from(Array(this.sections.length).keys())
            let section = sections.reduce((total,e) => {
                let el        = this.EL(e)
                let distance  =  this.horizontal ? el.rleft : el.rtop
                let size      =  this.horizontal ? el.rwidth : el.rheight

                let center =  distance + (size/2) - (this.pageSize/2)

                if(this.distance > -25) return [0]

                return Math.abs(center) < Math.abs(total[1]) ? [e, center] : total
            },[0,this.getWindow.w])

            return(section[0])
        },
        calculateScrollingSpeed(e) {
            let sm          = 3 // Speed multiplier
            let thisTime    = e.timeStamp
            let di          = thisTime - this.lastScrollTime

            di              = Math.ceil(di/sm)
            di              = Math.min(Math.max(di, 1), sm*8)
            di              = ((sm*13) - di)/sm
            di              = di == 0 ? 1 : di
            this.scrollingSpeed   = this.getTouch ? 1 : di
            this.lastScrollTime   = thisTime
        },
        handleScroll(e) {
            if(this.getRouteChanging) return
            if( (!this.showScroll && this.element == 'page') ||
                 !this.sections ||
                 !this.canMove  ||
                 this.getMenuPresent) return

            this.calculateBarHeight()
            this.calculateScrollingSpeed(e)

            // Getting wheeldata and separate touch and scroll behavior
            let wheelY = -e.deltaY * 20
            let wheelX = -e.deltaX * 20

            let wheelMove = wheelY == 0 && wheelX != 0 ? wheelX : wheelY

            if(wheelMove && !this.getTouch) {
                this.dir = wheelMove < 0
                this.moveIt(wheelMove)
            } else {
                this.dir = e < 0
                this.moveItTouch(e)
            }
        },
        runMove(dis, time) {
            let limitStart    = dis > 0 // bef0re the start point
            let limitEnd      = dis < -this.limit

            if(limitStart) {
                this.dis = 0
            } else if(limitEnd) {
                this.dis = -this.limit
            } else {
                this.dis = dis
            }

          // Not passing the sides
            if(limitStart || limitEnd) {
                this.startingDir    = null
                this.canMove        = true
            }

            // partial move
            let x = this.horizontal ? this.dis : 0
            let y = this.horizontal ? 0 : this.dis
            gsap.killTweensOf(this.element)
            gsap.to(this.element, time, {y, x, ease: 'power1.out', onComplete: () => {
                if(!this.touching && !this.pageIsGrabbing && !this.isGrabbed && !this.getRouteChanging) {
                    this.runFinalMove()
                }
            } })
        },

        // ANCHOR Move touch
        moveItTouch(e, swipeTime = null) {
            if (isNaN(e)) return

            let time = this.getTouch ? 0 : 0.6
            if(swipeTime) time = swipeTime

            let dis = e + this.lastDistance
            this.runMove(dis, time)
        },

        // ANCHOR Move Scroll
        moveIt(dis) {
            if(!this.isGrabbed) dis = dis > 0 ? this.threshold : -this.threshold;

            gsap.killTweensOf(this.element)

            // FINAL
            this.runMove(this.dis + dis, 1)
        },
        runFinalMove() {
            if(!this.getSnap) return
            let section             = this.currentSection
            let sectionLiveChange   = Math.abs(this.lastDistance - this.distance) > this.pageSize/2

            if(this.startingDir == null) this.startingDir = this.dir

            if(sectionLiveChange || this.dir != this.startingDir || this.isGrabbed || this.pageIsGrabbing ) {
                this.currentSection = this.getVisible()
            } else {
                if(!this.horizontal && this.getTop > -25) {
                    this.currentSection = 0
                } else if(Math.abs(this.lastDistance - this.distance) > this.threshold) {
                    this.canMove = false
                    if(this.dir && this.currentSection != this.numberOfSections-1) {
                        this.currentSection+=1
                    } else {
                        this.currentSection-=1
                    }
                }
            }


            // Safe keeping only if current section goes off null, smaller than 0 or larger than number of valid sections
            if(isNaN(this.currentSection) || this.currentSection < 0 || this.currentSection > this.numberOfSections ) {
                this.currentSection = section
            }

            this.finalMove()
        },

        // ANCHOR __ FINAL MOVE
        finalMove(section = null, time = null) {
            if(this.getMenuPresent) return
            this.startingDir    = null


            if(section) this.currentSection = section

            let el = this.EL(this.currentSection)
            let dis = this.horizontal ? el.left : el.top

            let difference = this.horizontal ? (this.getWindow.w - el.rwidth) / 2 : (this.getWindow.h - el.rheight) / 2
            let finalDis = dis - difference

            if(finalDis < 0) finalDis = 0
            if(finalDis > this.limit) finalDis = this.limit


            this.dis = - finalDis  // Movement is Negative on the page

            if(time == null){
                time = this.getMovingTime(this.dis)
                time = Math.min(Math.max(time, 0.5), 1);
            }

            let x = this.horizontal ? this.dis : 0
            let y = this.horizontal ? 0 : this.dis

            gsap.killTweensOf(this.element)

            gsap.to(this.element, time, {y, x, ease: 'power1.in',  onComplete:() => {
                this.canMove = true
                this.lastDistance = this.dis
            } })

        },
        getMovingTime(dis) {
            let difference = Math.abs(dis - this.distance)
            let base = this.pageSize / 2
            return difference/base
        },
        reset() {
            this.updatePageMoving(false)
            this.currentSection = 0
            this.dis = 0

            this.calculateBarHeight()
        },
        stopSwiping() {
            if(!this.getPageMoving) return
            gsap.killTweensOf(this.element)
            this.dis = this.distance

        },
        touchStart(e){
            this.stopSwiping()
            this.touching = true
            this.lastDistance = this.distance
            this.tStartX = e.touches[0].clientX
            this.tStartY = e.touches[0].clientY
        },
        touchEnd() {
            this.touching = false

            // if(this.getTouch && Math.abs(this.swipingVelocity) > 0.1) {
            if(this.getTouch) {
                let velocity = this.horizontal ? this.swipingVelocity[0] : this.swipingVelocity[1]
                if(Math.abs(velocity) > 5) {
                    this.moveItTouch(velocity * 50, 1.7)
                    this.swipingVelocity = [0,0]
                } else {
                    this.runFinalMove()
                }
            }

        },
        touchMove(e) {
            gsap.killTweensOf(this.element)
            let tMoveX = (e.touches[0].clientX - this.tStartX)
            let tMoveY = (e.touches[0].clientY - this.tStartY)

            // if vertical or horizontal movement is more
            let dis = Math.abs(tMoveX) > Math.abs(tMoveY) ? tMoveX : tMoveY

            this.swipingVelocity    = [tMoveX - this.lastTouches[0], tMoveY - this.lastTouches[1]]
            this.lastTouches        = [tMoveX, tMoveY]

            this.handleScroll(dis)
        },
        resizeReset() {
            clearTimeout(this.resizeTimeout)
            this.resizeTimeout = setTimeout(() => {
                if(!this.getSnap) {
                    if(this.dis < -this.limit) this.moveItTouch(-this.limit)
                } else {
                    this.finalMove()
                }
                this.calculateBarHeight()
            }, 150);
        },
        moveHandle() {
            if(this.getTouch) return
            let d = this.dis / this.limit * this.barHeight || 0

            gsap.killTweensOf('.scroll_bar-handle')
            let time = this.isGrabbed ? 1 : 2
            gsap.to('.scroll_bar-handle', time, {y:-d, ease:"back.out(1)"})

            if(this.isGrabbed) this.updateCursor(true)
            // Calculation the position of handle on the page, by adding "d" to the top of the handle bar
            let bar = this.$refs.bar
            if(bar) {
                this.handlePosition = [bar.getBoundingClientRect().left, bar.getBoundingClientRect().top - d]
            }
        },
        mouseUp() {
            if(this.pageIsGrabbing) {
                this.grabEnd()
            } else {
                clearTimeout(this.pageGrabStartTimeout)
            }
        },

        // ANCHOR Grabbing
        grabStart(e) {
             this.updatePage()
             this.lastDistance = this.distance
            let ePosition
            if(this.pageIsGrabbing) {
                ePosition = this.horizontal ? e.x : e.y
            } else {
                ePosition = e.y
            }
            this.gStart = ePosition

            this.calculateBarHeight()
            this.moveHandle()
            this.handleResize()

            if(!this.pageIsGrabbing) {
                this.isGrabbed = true
                this.updateCursor('nav')
            }
            window.addEventListener("mousemove", this.grabbing)
        },
        grabEnd() {
            // clearTimeout(this.pageGrabStartTimeout)
            this.updateGrabCursor(false)
            this.runFinalMove()
            this.updateCursor(false)
            this.isGrabbed = false
            this.pageIsGrabbing = false
            this.handleResize(false)
            this.updatePageIsGrabbing(false)
            window.removeEventListener("mousemove", this.grabbing)
        },
        grabbing(e) {
            if(e.which == 0) {
                this.grabEnd()
                return
            }
            if(!this.isGrabbed && !this.pageIsGrabbing) {
                this.updateCursor(false)
                return
            }
            this.updatePageIsGrabbing(true)

            let ePosition, reference

            if(this.pageIsGrabbing) {
                ePosition = this.horizontal ? e.x : e.y
                reference = this.pageGrabbingSpeed
            } else {
                ePosition = e.y
                reference = this.barHeight
            }

            let dir = this.pageIsGrabbing ? 1 : -1
            let dis = ePosition - this.gStart
                dis = dis / reference * this.limit

            this.moveItTouch(dis * dir)
        },

        // ANCHOR Page Grabbing
        pageGrab(e, start=true) {
            // If cursor is over input elements
            if(start && this.getCursor == 'input') return

            clearTimeout(this.pageGrabStartTimeout)
            this.pageGrabStartTimeout = setTimeout(() => {
                this.pageGrabRun(e, start)
            }, 150)
        },
        pageGrabRun(e, start) {
            if(this.isGrabbed || this.getTouch || !this.getCanGrab) return
            if(start) {
                this.updateGrabCursor(true)
                this.pageIsGrabbing = true
                this.grabStart(e)
            } else {
                this.pageIsGrabbing = false
                this.updateGrabCursor(false)
                this.grabEnd(e)
            }
        },

        handleResize(mode = true) {
            let height = mode || this.isGrabbed ? 20 : 25
            gsap.killTweensOf('.handle_nch')
            gsap.to('.handle_nch', 0.5, { height, delay: 0.2 })
        },

        EL(section=this.currentSection) {
            let thisSection = this.sections[section]
            let res = { el:null, left:0, top:0, width:0, height:0, rleft:0, rtop:0, rwidth:0, rheight:0  }
            if(!thisSection) return res

            let el

            if(thisSection.$el) {
                el = thisSection.$el
            } else if(thisSection[0]) {
                el = thisSection[0]
            } else {
                el = thisSection
            }

            if (!el) { return res }

            let rect = el.getBoundingClientRect()

            res.el        = el

            res.left      = el.offsetLeft
            res.top       = el.offsetTop
            res.width     = el.offsetWidth
            res.height    = el.offsetHeight

            res.rleft     = rect.left
            res.rtop      = rect.top
            res.rwidth    = rect.width
            res.rheight   = rect.height

            return res
        },
        calculateBarHeight() {
            let bar = this.$refs.bar
            if(bar) this.barHeight = bar.getBoundingClientRect().height
        },

        onMoving() {
            if(this.getMenuPresent || this.getPopupActive) return
            this.moveHandle()
            this.updatePageMoving(true)
            clearTimeout(this.leftTimeout)
            this.leftTimeout = setTimeout(() => {
                this.updatePageMoving(false)
            }, 100);
        },

        // ANCHOR QUERY
		gotoQuery(animate) {
            let query = this.$route.query.s
            if(query) {
                let section = this.getSectionsNames.indexOf(query)
                if(section >= 0) {
                    let time = animate ? 1 : 0
                    this.finalMove(section, time)
                } else {
                    setTimeout(() => {
                        this.gotoQuery()
                    }, 50);
                }
            }
        },
    },
    mounted() {
        // this.initiateSwipe()
        this.reset()
        window.addEventListener('wheel', this.handleScroll)
        // Touch
        window.addEventListener("touchstart", this.touchStart, false)
        window.addEventListener("touchend", this.touchEnd, false)
        window.addEventListener("touchmove", this.touchMove, false)
        // Resize
        window.addEventListener("resize", this.resizeReset, false)
        // Mouse Up
        window.addEventListener("mouseup", this.mouseUp)
        // window.addEventListener("click", this.stopSwiping)

        // Page Grab
        document.getElementById('main').addEventListener("mousedown", this.pageGrab)
        document.getElementById('main').addEventListener("mouseup", e => {this.pageGrab(e, false)})
        this.currentRoute = this.$route.name
        this.gotoQuery()
    },
    destroyed() {
        this.reset()
        window.removeEventListener('wheel', this.handleScroll)
    },
    watch: {
        sections() {
            this.currentSection = 0
        },
        $route(val){
            let animate = this.getQuery && this.getQuery.animate
            if(val.name == this.currentRoute && !animate) return
            this.currentRoute = val.name
            this.reset()
            this.gotoQuery(animate)
        },
        currentSection(val) {
            let query = val == 0 ? null : this.getSectionsNames[val]
            this.updateQuery(query)
        },
        updateHandleValues(val) { this.updateHandle(val) },
        getLeft() {
            this.onMoving()
        },
        getTop() {
            this.onMoving()
        },

        getPageMoving(val) {
            if(this.isGrabbed) return
            if(val) this.handleResize()
            else this.handleResize(false)
        },
        getPopupActive(val) {
            this.element = val ? '#popup_wrapper' : '#page'
            this.updatePage()
        }
    }
}
</script>