


// Font Size min & max
export const fontsizeCheck = (el, e) => {
	el.style.fontSize = null
	let style = window.getComputedStyle(el)
	let fs   = parseInt(style['font-size'])
	let val  = e.value
	let valMin  = val[0] ? val[0] : val
	let valMax  = val[1] ? val[1] : null



	if(fs < valMin) {
		val = valMin + 'px'
	} else if (valMax && fs > valMax) {
		val = valMax + 'px'
	} else {
		val = null
	}

	el.style.fontSize = val
}