<template>
	<button
		class="lightdark fl-c"
		id="lightDark"
		@click="swapLight"
		@mousemove="updateCursorEl"
		@mouseleave="updateCursorEl">
			<svg width="73.9" height="73.9" viewBox="0 0 73.9 73.9" v-bind:qsvg="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M36.95 59.77c-6.1 0-11.83-2.37-16.14-6.68-8.9-8.9-8.9-23.38 0-32.27 4.31-4.31 10.04-6.68 16.14-6.68s11.83 2.37 16.14 6.68c8.9 8.9 8.9 23.38 0 32.27-4.31 4.31-10.04 6.68-16.14 6.68zm0-43.64c-5.56 0-10.79 2.17-14.72 6.1-8.12 8.12-8.12 21.33 0 29.45 8.12 8.12 21.33 8.12 29.45 0 8.12-8.12 8.12-21.33 0-29.45-3.93-3.93-9.16-6.1-14.72-6.1z"/><path d="M37.47 58.76V15.14a21.742 21.742 0 00-15.95 6.38c-8.52 8.52-8.52 22.34 0 30.86a21.742 21.742 0 0015.95 6.38z"/></svg>
	</button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: "LightDark",
	computed: {
		...mapGetters('GeneralStore', ['getLight'])
	},
    methods: {
        ...mapActions ("GeneralStore", ['swapLight', 'updateCursorEl']),
    }
}
</script>