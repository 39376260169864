let c = console.log
c()


import axios from 'axios'

const state = {
    loaded    : false,
    home      : null,
    works     : null,
    work      : null,
    about     : null,
    "404"     : null,
    contact   : null
}

// GETTERS
const getters = {
    getPageInfo: state => (pageName = null) => pageName ? state[pageName] : state,
    getTitle: state => state.about.title,
    getSlogan: state => state.home.slogan,
    pagesLoaded: state => state.loaded
}

// ACTION
const actions = {
    async loadPages({ commit }) {
        let res = await axios('/data/pages.json')
        commit('loadPages', res.data)
    },
    swapLight({ commit }, pageName) {
        commit('swapLight', pageName)
    }
}

// MUTATION
const mutations = {
    swapLight: (state, pageName) => {
        const currentInfo = state[pageName]
        currentInfo.light = !currentInfo.light
        state[pageName] = currentInfo
    },
    loadPages: (state, res) => {
        state.home    = res.home
        state.works   = res.works
        state["404"]  = res["404"]
        state.work    = res.work
        state.about   = res.about
        state.contact = res.contact
        state.loaded  = true
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}