<template>
    <div id="page_cover" ref="cover" class="fi wh b-l" :style="style">
		<div class="page_cover-img bi wh t-l"
			v-for="id in ids"
			:key="id"
			v-show="id == workId"
			:style="`background-image: url(/assets/img/cover/${id}.jpg)`">
		</div>
    </div>
</template>

<script>
import gsap from "gsap"
import { mapActions, mapGetters } from 'vuex'


let c = console.log
c()

export default {
    name: "PageCover",
	data: () => ({
		routeFrom   : null,
		routeTo     : null,
		next        : null,
		cover       : '',
		style       : '',
		workId: ''
    }),
    mounted() {

		this.getStyle(this.$route)
		this.coverOnPageChange()
		this.$router.beforeEach((to, from, next) => {
			if(to.name == this.$route.name) {
				next()
				return
			}

			if(this.getRouteChanging) return

			this.next        = next
			this.routeTo     = to
			this.routeFrom   = from

			this.getStyle(to)
			this.coverOnPageChange()
		})
	},
    computed: {
        ...mapGetters("GeneralStore", ['getRouteChanging','getWindow']),
        ...mapGetters("WorksStore", ['getWork']),
		...mapGetters("PagesStore", ['getPageInfo']),
		coverMove() { return this.getWindow.h / 2 },
		coverHeight() { return this.getWindow.h + this.coverMove },
		ids() {
			return this.getWork().reduce((total,e) => {
				return [...total, e.id]
			}, [])
		}
	},
    methods: {
		...mapActions("GeneralStore", ['updateSiteCovered','updatePage','updateRouteChanging']),
		getStyle(route) {

			if(!route) return

			// background imge
			if(route.name == 'work') {
				this.workId = this.getWork(route.params.slug)[0].id
			} else if (this.routeFrom && this.routeFrom.name != 'work'){
				this.workId = null
			}

			// Background Color
			let backIsLight = this.getPageInfo(route.name)['light']
			let back = backIsLight ? 'var(--cover-light)' : 'var(--cover-dark)'

			this.style =  {
				backgroundColor: back
			}
		},
        coverOnPageChange() {
			let co = '#page_cover'
			let ho = '#hoborg'
			let si = '#site_wrapper'
			let t       = 1
			let ease   = "power3.inOut"

			gsap.killTweensOf(co, ho, si)
			this.updateSiteCovered(false)
			this.updateRouteChanging(true)

			let tl = gsap.timeline({ repeat:false })
			let tl2 = gsap.timeline({ repeat:false })
			tl.to(si, 0, { height:"100%", bottom:0, top:"initial" }, 'start')
			tl.to(ho, 0, { bottom: 0, top:'initial' }, 'start')
			tl.to(co, 0, { y:-200 }, 'start')

			tl.to(co, t, { ease,y:0 }, 'a')
			tl.to(si, t, { ease, height:0}, 'a')
			tl.to(ho, t, { ease, y:50, onComplete: () => {
				if(this.next) this.next()
				this.updateSiteCovered(true)
				setTimeout(() => {
					loadpage()
				}, 200)
			} }, 'a')

			let loadpage = () => {
				gsap.killTweensOf(co, ho, si)
				tl2.to(ho, 0, { bottom: 'initial', top:0, y:-50}, 'b')
				tl2.to(si, 0, { bottom:'initial', top:0}, 'b')

				tl2.to(si, t, { ease, height: '100%' }, 'c')
				tl2.to(ho, t, { ease, y:0, onComplete: () => {
					this.updateSiteCovered(false)
					this.updateRouteChanging(false)
					this.updatePage()
				} }, 'c')
			}
		}
	},
	watch: {
		$route(val) {
			this.getStyle(val)
		}
	}
}
</script>