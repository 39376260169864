<template>
    <section class="fl-c">
        <div class="section_content fl-co-l">
            <!-- <div class="section_n fl-c"><span>3</span><span class="_n_l">/</span><span>3</span></div> -->
            <span class="mono">A brief about me</span>
            <p>I began my career as a user experience designer and web application designer in 2011; accumulatively, I have 10+ years of experience in UX and product design. In addition to running a successful business delivering designs and tools used by 50,000 designers and agencies, I also contracted with renowned companies like Adobe and Shopify. My strengths lie in leading design teams, designing, and architecting user experiences and applications with a specific focus on the web and design community.</p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'meSection',
    data: () => ({

    }),
    mounted() {
        // gsap.from('.h1_large', {duration: 3, y:20, opacity: 0, delay: 0.2})
    },
    methods: {

    }
}
</script>