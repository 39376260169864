import Vue from 'vue'
import Site from './Site.vue'
import store from './store'
import router from './router'
import { globalMixins } from './mixins'
import { fontsizeCheck } from '@/assets/js/helper'






// ANCHOR Directives
// Minimum font-size
Vue.directive('fsize', {
	inserted: function (el, e) {
		fontsizeCheck(el,e)
		window.addEventListener('resize', () => { fontsizeCheck(el,e) })
	}
})


document.addEventListener('touchmove', function (event) {
	if (event.scale !== 1) event.preventDefault()
}, { passive: false })


Vue.config.productionTip = false
Vue.mixin(globalMixins)

new Vue({
	store,
	router,
	render: h => h(Site)
}).$mount('#kol')