<template>
    <div id="nav" class="fl-c ab r-t" :class="[{ open: getMenuStatus, pe: getPageIsGrabbing }]">
        <div class="main-nav" @mousemove="updateCursorEl" @mouseleave="updateCursorEl" @click="toggleMenuInitiate" >
            <div class="menu-trigger pe fl-co-c">
                <div class="t-li_12">
                    <div class="menu-trigger_line t-li_1" v-html="lineSvg"></div>
                    <div class="menu-trigger_line t-li_2" v-html="lineSvg"></div>
                </div>
                <div class="t-li_34">
                    <div class="menu-trigger_line t-li_3" v-html="lineSvg"></div>
                    <div class="menu-trigger_line t-li_4" v-html="lineSvg"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import gsap from "gsap"




export default {
    data: () => ({
        go                : true,
        t                 : gsap.timeline({ repeat:false }),
        lineSvg           : ' <svg><line x1="0" y1="0" x2="100" y2="0"></line></svg>',
    }),
    mounted() {
         this.toggle(false)
         this.toggleTimeline()
    },
    computed: {
        ...mapGetters ("GeneralStore", [
            "getMenuStatus",
            "getPageIsGrabbing",
            "getPageMoving",
            "getPopupActive",
            'getSiteCovered'
        ]),
    },
    methods: {
        ...mapActions ("GeneralStore", [
                'toggleMenu',
                'updateCursor',
                'updateCursorEl',
                'updatePopupActive'
            ]),
        toggleTimeline() {
            let t       = this.t
            let t1      = '.t-li_1'
            let t2      = '.t-li_2'
            let t3      = '.t-li_3'
            let t4      = '.t-li_4'
            let t12     = '.t-li_12'
            let t34     = '.t-li_34'
            let wait    = '-=0.3'
            let ti      = 0.3
            gsap.killTweensOf(t,t1,t2,t3,t4,t12,t34)
            t.pause()
            t.to(t12, ti, { x:0, y:6, ease:'back.in(3)' }, 'a')
            t.to(t34, ti, { y:-5, x:0, ease:'back.in(3)', delay: 0.1}, 'a')
            t.to(t1, ti, { rotate:45, ease:'back.out(3)', delay:0.2})
            t.to(t2, ti, { rotate:-45, ease:'back.out(3)'}, wait)
            t.to(t3, ti+0.2, { rotate:-45, ease:'back.out(4)'}, wait)
            t.to(t4, ti+0.4, { rotate:45, ease:'back.out(4)'}, wait)
        },
        toggle(e) {
            if(e) {
                this.t.play()
            } else {
                this.t.reverse()
            }
        },
        sidenavWidth() {
            return document.getElementById('side_nav').offsetWidth
        },
        toggleMenuInitiate() {

            if(!this.go || this.getPageMoving) return
            this.go = false
            setTimeout(() => { this.go = true }, 500)

            if(this.getPopupActive) {
                this.updatePopupActive(false)
            } else {
                this.toggleMenu()
            }
        }
    },
    watch: {
        getMenuStatus(val) {
            this.toggle(val)
        },
        getPopupActive(val) {
            this.toggle(val)
        },
        getSiteCovered(v) {
            if(!v || !this.getMenuStatus) return
            this.t.seek(0);
            this.toggleMenu(false)
        }
    }
}
</script>