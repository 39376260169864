<template>
    <section class="fl-c">
        <div class="section_content fl-co-l">
            <!-- <div class="section_n fl-c"><span>2</span><span class="_n_l">/</span><span>3</span></div> -->
            <div class="fl-co-l-c">
                <span class="mono">Featured work</span>
                <div class="lworks fl-co-l">
                        <router-link
                            tag="div"
                            v-for="w in works"
                            :key="w.id"
                            @mousemove.native="updateCursorEl([$event,{x:false}])" @mouseleave.native="updateCursorEl"
                            class="lwork fl-c-l"
                            :to="{name: 'work', params: {slug: w.slug}}"
                        >
                            <div class="lwork-name pe medium-type">{{ w.name }} <span>{{ w.brief }}</span></div>
                        </router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'FeaturedWorks',
    computed: {
        ...mapGetters ("WorksStore",['getFeaturedWork']),
        works() {
            return this.getFeaturedWork
        }
    },
    methods: {
        ...mapActions ("GeneralStore", ['updateCursor','toggleMenu','updateCursorEl'])
    }
}
</script>