import Vue  from 'vue'
import Vuex from 'vuex'
import WorksStore     from './modules/WorksStore'
import GeneralStore  from './modules/GeneralStore'
import ImageStore  from './modules/ImageStore'
import PagesStore  from './modules/PagesStore'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    WorksStore,
    GeneralStore,
    PagesStore,
    ImageStore
  }
})